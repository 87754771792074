import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/dashboard'
export default {
  namespaced: true,
  state: {
    counters: [],
    pendingList: [],
  },
  getters: {
    counters(state) {
      return state.counters
    },
    pendingList(state) {
      return state.pendingList
    },
  },
  mutations: {
    SET_COUNTERS(state, data) {
      state.counters = data
    },
    SET_PENDING_LIST(state, data) {
      state.pendingList = data
    },
  },
  actions: {
    getCounters({ commit }) {
      axiosIns
        .post(`${ROOT_URL}/counters`)
        .then(response => {
          commit('SET_COUNTERS', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getPendingList({ commit }) {
      axiosIns
        .post(`${ROOT_URL}/pendingList`)
        .then(response => {
          commit('SET_PENDING_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
