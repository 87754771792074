export default [
  {
    path: '/admin/supports',
    name: 'Supports',
    component: () => import('@/views/Admin/Supports/Index.vue'),
    meta: {
      pageTitle: 'Destek Talepleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPORT_LIST',
      action: 'read',
    },
  },
  {
    path: '/admin/supports/customer-search',
    name: 'SupportCustomerSearch',
    component: () => import('@/views/Admin/Supports/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Destek Talepleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/supports',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPORT_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/supports/add/:id_customers',
    name: 'SupportAdd',
    component: () => import('@/views/Admin/Supports/Add.vue'),
    meta: {
      pageTitle: 'Destek Talepleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/supports',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          to: '/admin/supports/customer-search',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPORT_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/supports/edit/:id',
    name: 'SupportEdit',
    component: () => import('@/views/Admin/Supports/Edit.vue'),
    meta: {
      pageTitle: 'Destek Talepleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/supports',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPORT_EDIT',
      action: 'read',
    },
  },
  {
    path: '/admin/supports/view/:id',
    name: 'SupportEdit',
    component: () => import('@/views/Admin/Supports/View.vue'),
    meta: {
      pageTitle: 'Destek Talepleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/supports',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_SUPPORT_VIEW',
      action: 'read',
    },
  },
]
