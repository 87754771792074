export default [
  {
    path: '/client/supports',
    name: 'ClientSupports',
    component: () => import('@/views/Client/Supports/Index.vue'),
    meta: {
      pageTitle: 'Destek Talepleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'CLIENT_SUPPORT_LIST',
      action: 'read',
    },
  },
  {
    path: '/client/supports/add',
    name: 'ClientSupportAdd',
    component: () => import('@/views/Client/Supports/Add.vue'),
    meta: {
      pageTitle: 'Destek Talepleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/client/supports',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'CLIENT_SUPPORT_ADD',
      action: 'read',
    },
  },
  {
    path: '/client/supports/view/:id',
    name: 'ClientSupportView',
    component: () => import('@/views/Client/Supports/View.vue'),
    meta: {
      pageTitle: 'Destek Talepleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/client/supports',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'CLIENT_SUPPORT_VIEW',
      action: 'read',
    },
  },
]
