export default [
  {
    path: '/reports/interviews',
    name: 'ReportInterviews',
    component: () => import('@/views/Admin/Reports/Interviews/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Görüşmeler',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_INTERVIEW',
      action: 'read',
    },
  },
  {
    path: '/reports/offers',
    name: 'ReportOffers',
    component: () => import('@/views/Admin/Reports/Offers/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Teklifler',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_OFFER',
      action: 'read',
    },
  },
  {
    path: '/reports/services',
    name: 'ReportServices',
    component: () => import('@/views/Admin/Reports/Services/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Servis',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS_SERVICE',
      action: 'read',
    },
  },
]
