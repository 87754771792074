export default [
  {
    path: '/admin/defines/cities',
    name: 'Cities',
    component: () => import('@/views/Admin/Defines/Cities/Index.vue'),
    meta: {
      pageTitle: 'Şehirler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/cities/add',
    name: 'CityAdd',
    component: () => import('@/views/Admin/Defines/Cities/Add.vue'),
    meta: {
      pageTitle: 'Şehirler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/cities',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/cities/edit/:id',
    name: 'CityEdit',
    component: () => import('@/views/Admin/Defines/Cities/Edit.vue'),
    meta: {
      pageTitle: 'Şehirler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/cities',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
