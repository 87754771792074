export default [
  {
    path: '/admin/projects',
    name: 'Projects',
    component: () => import('@/views/Admin/Projects/Index.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_LIST',
      action: 'read',
    },
  },
  {
    path: '/admin/projects/customer-search',
    name: 'ProjectCustomerSearch',
    component: () => import('@/views/Admin/Projects/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/projects',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/projects/add/:id_customers',
    name: 'ProjectAdd',
    component: () => import('@/views/Admin/Projects/Add.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/projects',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          to: '/admin/projects/customer-search',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/projects/edit/:id',
    name: 'ProjectEdit',
    component: () => import('@/views/Admin/Projects/Edit.vue'),
    meta: {
      pageTitle: 'Projeler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/projects',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_PROJECT_EDIT',
      action: 'read',
    },
  },
]
