import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
/* CORE */
import app from './routes/app'
/* ADMIN:CORE */
import configs from './routes/Admin/Core/Configs'
import users from './routes/Admin/Core/Users'
import userTypes from './routes/Admin/Core/User_types'
/* ADMIN:DEFINES */
import cities from './routes/Admin/Defines/Cities'
import countries from './routes/Admin/Defines/Countries'
import customerTypes from './routes/Admin/Defines/Customer_types'
import meets from './routes/Admin/Defines/Meets'
import sectors from './routes/Admin/Defines/Sectors'
import taskTypes from './routes/Admin/Defines/Task_types'
import taskStatuses from './routes/Admin/Defines/Task_statuses'
/* ADMIN:APP */
import customers from './routes/Admin/App/Customers'
import projects from './routes/Admin/App/Projects'
import supports from './routes/Admin/App/Supports'
import reports from './routes/Admin/App/Reports'
import tasks from './routes/Admin/App/Tasks'
/* CLIENT:APP */
import clientSupports from './routes/Client/Supports'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...app,
    ...configs,
    ...users,
    ...userTypes,
    ...cities,
    ...countries,
    ...customerTypes,
    ...meets,
    ...sectors,
    ...taskTypes,
    ...taskStatuses,
    ...customers,
    ...projects,
    ...supports,
    ...reports,
    ...tasks,
    ...clientSupports,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
