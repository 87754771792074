import axiosIns from '@/libs/axios'

const ROOT_URL = '/Client/data/dashboard'
export default {
  namespaced: true,
  state: {
    pendingList: [],
  },
  getters: {
    pendingList(state) {
      return state.pendingList
    },
  },
  mutations: {
    SET_PENDING_LIST(state, data) {
      state.pendingList = data
    },
  },
  actions: {
    getPendingApproval({ commit }) {
      axiosIns
        .post(`${ROOT_URL}/pendingApproval`)
        .then(response => {
          commit('SET_PENDING_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
