export default [
  {
    path: '/admin/defines/meets',
    name: 'Meets',
    component: () => import('@/views/Admin/Defines/Meets/Index.vue'),
    meta: {
      pageTitle: 'Temas Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/meets/add',
    name: 'MeetAdd',
    component: () => import('@/views/Admin/Defines/Meets/Add.vue'),
    meta: {
      pageTitle: 'Temas Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/meets',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
  {
    path: '/admin/defines/meets/edit/:id',
    name: 'MeetEdit',
    component: () => import('@/views/Admin/Defines/Meets/Edit.vue'),
    meta: {
      pageTitle: 'Temas Tipleri',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/defines/meets',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DEFINES_ALL',
      action: 'read',
    },
  },
]
