import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/tasks'
export default {
  namespaced: true,
  state: {
    dataList: [],
    userTasks: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      title: null,
      text: null,
      sdate: null,
      deadline: null,
      id_priorities: null,
      id_task_types: null,
      id_task_statuses: '1',
      id_projects: null,
      lines: [],
      files: [],
      task_users: [],
    },
    fastTaskItem: {
      id: null,
      title: null,
      text: null,
      sdate: null,
      deadline: null,
      id_priorities: null,
      id_task_types: null,
      id_task_statuses: '1',
      id_supports: null,
      id_projects: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    filterData: {
      keyword: null,
      id_task_statuses: ['1', '2'],
      id_priorities: [],
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    userTasks(state) {
      return state.userTasks
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    fastTaskItem(state) {
      return state.fastTaskItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    filterData(state) {
      return state.filterData
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_USER_TASKS(state, data) {
      state.userTasks = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        title: null,
        text: null,
        sdate: null,
        deadline: null,
        id_priorities: null,
        id_task_types: null,
        id_task_statuses: '1',
        id_projects: null,
        lines: [],
        files: [],
        task_users: [],
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getUserTasks({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/getUserTasks`, data)
        .then(response => {
          commit('SET_USER_TASKS', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, params) {
      const data = JSON.stringify({
        where: {
          'tasks.id': params.id,
        },
        isUpdate: params.isUpdate,
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataItemCustom({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    saveFastData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveFastData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
