import axiosIns from '@/libs/axios'

const ROOT_URL = '/Auth/AppLogin'
export default {
  namespaced: true,
  state: {
    authResult: {},
  },
  getters: {
    authResult(state) {
      return state.authResult
    },
  },
  mutations: {
    SET_AUTH_RESULT(state, data) {
      state.authResult = data
    },
  },
  actions: {
    appLogin({ commit }, appToken) {
      return axiosIns
        .post(ROOT_URL, { appToken })
        .then(response => {
          commit('SET_AUTH_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
