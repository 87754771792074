import axiosIns from '@/libs/axios'

const ROOT_URL = '/Admin/data/task_files'
export default {
  namespaced: true,
  state: {
    dataItem: {
      title: 'Dosya Eki',
      upload_file: null,
      id_tasks: null,
    },
    dataSave: {
      message: null,
      status: null,
      data: null,
      id: null,
    },
    uploading: false,
  },
  getters: {
    dataItem(state) {
      return state.dataItem
    },
    uploading(state) {
      return state.uploading
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {}
    },
    SET_UPLOADING(state, data) {
      state.uploading = data
    },
  },
  actions: {
    uploadData({ commit }, postData) {
      commit('SET_UPLOADING', true)
      const formData = new FormData()
      formData.append('title', postData.title)
      formData.append('upload_file', postData.upload_file)
      formData.append('id_tasks', postData.id_tasks)
      return axiosIns
        .post(ROOT_URL, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          commit('SET_UPLOADING', false)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
