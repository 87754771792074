export default [
  {
    path: '/admin/tasks',
    name: 'Tasks',
    component: () => import('@/views/Admin/Tasks/Index.vue'),
    meta: {
      pageTitle: 'Görevler',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_TASK_LIST',
      action: 'read',
    },
  },
  {
    path: '/admin/tasks/user-tasks',
    name: 'UserTasks',
    component: () => import('@/views/Admin/Tasks/UserTasks.vue'),
    meta: {
      pageTitle: 'Görevler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tasks',
          active: false,
        },
        {
          text: 'Görevlerim',
          active: true,
        },
      ],
      resource: 'ADMIN_TASK_LIST',
      action: 'read',
    },
  },
  {
    path: '/admin/tasks/customer-search',
    name: 'TaskCustomerSearch',
    component: () => import('@/views/Admin/Tasks/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Görevler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tasks',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_TASK_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/tasks/add/:id_customers',
    name: 'TaskAdd',
    component: () => import('@/views/Admin/Tasks/Add.vue'),
    meta: {
      pageTitle: 'Görevler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tasks',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          to: '/admin/tasks/customer-search',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_TASK_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/tasks/edit/:id',
    name: 'TaskEdit',
    component: () => import('@/views/Admin/Tasks/Edit.vue'),
    meta: {
      pageTitle: 'Görevler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tasks',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_TASK_EDIT',
      action: 'read',
    },
  },
  {
    path: '/admin/tasks/view/:id',
    name: 'TaskView',
    component: () => import('@/views/Admin/Tasks/View.vue'),
    meta: {
      pageTitle: 'Görevler',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/tasks',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMIN_TASK_VIEW',
      action: 'read',
    },
  },
]
